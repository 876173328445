import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Module from "../components/module"
import Stack from "../components/stack"
import Text from "../components/text"
import Heading from "../components/heading"
import Hero from "../components/hero"
import colors from "../colors"
import Features from "../components/features"
import TextContainer from "../components/textContainer"
import TalkToUs from "../components/talkToUs"
import Container from "../components/container"

const QUERY = graphql`
  {
    sanityHeroImage(name: { eq: "careers" }) {
      id
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`

const CareersPage = () => {
  const data = useStaticQuery(QUERY)
  // const image = getImage(data.file)
  return (
    <Layout>
      <Hero
        title="Work with us."
        image={data.sanityHeroImage.image.asset.gatsbyImageData}
      >
        <Heading as="h1" inverted>
          Work with us.
        </Heading>
      </Hero>
      <Module>
        <Container>
          <TextContainer>
            <Stack>
              <Heading as="h2">
                We believe people and culture are the key to delivering
                excellent healthcare to our patients.
              </Heading>
              <Text large>
                Our priority is finding the right doctors, nurses and
                administrative staff. And once we recruit them, we take really
                good care of them—through facilitating professional development,
                empowering their passion and supporting all their endeavours.
              </Text>
            </Stack>
          </TextContainer>
        </Container>
      </Module>
      <Module>
        <Container>
          <Stack gap={50 / 16}>
            <TextContainer>
              <Stack>
                <Heading as="h2">Why Q Medical?</Heading>
                <Text large>
                  Our dedication to excellence drives us to deliver unparalleled
                  clinical service.
                </Text>
              </Stack>
            </TextContainer>

            <Features
              background={colors.teal}
              features={[
                <Stack gap={20 / 16}>
                  <Heading as="h3" inverted>
                    Our care is patient-centric
                  </Heading>
                  <Text inverted>
                    The work we do is about people, which means our job is both
                    clinical and relational. We enthusiastically serve our
                    patients through relationships built on trust, communication
                    and authenticity. When we genuinely care about each person
                    we look after, our clinical practice exceeds expectations.
                  </Text>
                </Stack>,
                <Stack gap={20 / 16}>
                  <Heading as="h3" inverted>
                    Your growth matters to us
                  </Heading>
                  <Text inverted>
                    When it comes to medicine, we strongly believe there is
                    always more to learn, discover and practice. We ensure our
                    doctors have ample opportunity to train and collaborate, so
                    our patients receive the best care possible, and we
                    continually fuel our doctors’ excitement for medicine.
                  </Text>
                </Stack>,
                <Stack gap={20 / 16}>
                  <Heading as="h3" inverted>
                    We’re doctor-led
                  </Heading>
                  <Text inverted>
                    Our practice is run by doctors, which means medicine always
                    comes before the bottom line. We’re deeply devoted to
                    holistic patient care and quality medical practice, and that
                    attitude trickles down to each staff member and every
                    business decision.
                  </Text>
                </Stack>,
              ]}
            />
          </Stack>
        </Container>
      </Module>
      <TalkToUs noButton>
        If this feels like the right fit for you, we’d love to hear from you.
        <br />
        Please email{" "}
        <a href="mailto:info@q-medical.com.au">careers@q-medical.com.au</a>.
      </TalkToUs>
    </Layout>
  )
}

export default CareersPage
